<template>
  <section class="pt-5">
    <header class="container custom-container bordercustom">
      <ul class="appbar mb-0">
        <li><v-icon @click="backBtn"> mdi-chevron-left </v-icon></li>
        <li class="text-center">Reset Password</li>
      </ul>
    </header>
    <v-container class="custom-container">
      <div class="boxauthoriation">
        <v-row>
          <v-col md="12">
            <div class="message">
              Please, enter your current password and new password to reset your
              password.
            </div>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="form-section"
            >
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="oldpassword"
                    label="Old Password"
                    :append-icon="showpasswordold ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="oldRules"
                    :type="showpasswordold ? 'text' : 'password'"
                    @click:append="showpasswordold = !showpasswordold"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="password"
                    label="New Password"
                    :append-icon="showpasswordnew ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showpasswordnew ? 'text' : 'password'"
                    @click:append="showpasswordnew = !showpasswordnew"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="submitbtn px-0">
                  <v-btn
                    :disabled="!valid"
                    class="submitbutton btn-dark"
                    @click="validate"
                    v-if="!loading"
                    type="submit"
                  >
                    Reset Password
                  </v-btn>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                  ></v-progress-circular>

                  <v-snackbar
                    v-model="snackbar"
                    v-if="allVerify.data != undefined"
                    color="red"
                  >
                    {{ allVerify.data.message }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ResetPassword",
  data() {
    return {
      allUserloading: false,
      valid: true,
      loading: false,
      oldpassword: "",
      password: "",
      snackbar: false,
      showpasswordold: false,
      showpasswordnew: false,
      oldRules: [(v) => !!v || "Old Password is required."],
      emptyRule: [(v) => !!v || "New Password is required !"],
      rules: {
        required: (value) => !!value || "New Password is required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    ...mapActions(["verify", "businessResetPassword"]),
    async validate() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        let userData = {
          oldpassword: this.oldpassword,
          password: this.password,
        };
        await this.businessResetPassword(userData);
        this.snackbar = true;
      }

      this.loading = false;
    },
    backBtn() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["allLoadingAuth", "allVerify", "allBusinessVerify"]),
  },
  created() {
    this.snackbar = true;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.boxauthoriation {
  padding: 15px 15px;
}
.message {
  padding: 0 00px 10px 10px;
  color: rgba(188, 188, 188, 1);
  font-size: 12px;
}
.registerlink {
  margin-top: 15px;
  a {
    margin-left: 5px;
  }
}

.submitbtn {
  text-align: center;
}
img {
  width: 150px;
}
.form-section {
  .col-md-6,
  .col-md-12 {
    padding: 0 20px !important;
  }
}
.titleform {
  margin-bottom: 20px;
}
.registerlink {
  margin-top: 20px;
  font-size: 14px;
}
.btn-dark {
  background: $btnColor !important;
  width: 95%;
  height: 50px !important;
}
</style>
