<template>
  <section>
    <header class="container custom-container bordercustom">
      <ul class="appbar mb-0">
        <li><v-icon @click="backBtn"> mdi-chevron-left </v-icon></li>
        <li class="text-center">Create a Account</li>
      </ul>
    </header>
    <v-container class="custom-container">
      <div class="boxauthoriation">
        <v-row>
          <v-col md="12">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="form-section"
            >
              <v-row>
                <v-col md="12" class="logo-space">
                  <v-row class="logo flex justify-center">
                    <router-link to="/login/business">
                      <img src="@/assets/images/logoheader.png" alt="" />
                    </router-link>
                  </v-row>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="fullname"
                    :label="
                      $route.params.slug == 'user' ? 'Full Name' : 'Company Name'
                    "
                    :rules="fullnameRule"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="password"
                    label="Password"
                    :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showpassword ? 'text' : 'password'"
                    @click:append="showpassword = !showpassword"
                    @keyup.enter.stop="validate"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="submitbtn px-0">
                  <v-btn
                    :disabled="!valid"
                    class="submitbutton btn-dark"
                    @click="validate"
                    v-if="!loading"
                  >
                    Register
                  </v-btn>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                  ></v-progress-circular>
                  <div class="message">
                    By clicking Register, I agree that I have read and accepted
                    the Postaam
                    <a href="#">Terms of Use and Privacy Policy</a>
                  </div>
                  <div class="registerlink">
                    Already have an account?<router-link to="/login/user"
                      >Login</router-link
                    >
                  </div>

                  <v-snackbar
                    v-model="snackbar"
                    v-if="allRegister.data != undefined"
                    color="green"
                  >
                    {{ allRegister.data.message }}
                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="red"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Register",
  computed: {
    ...mapGetters(["allLoadingAuth", "allRegister"]),
  },
  data: () => ({
    loading: false,
    valid: true,
    email: "",
    password: "",
    fullname: "",
    showpassword: false,
    snackbar: false,
    emailRules: [(v) => !!v || "Email is required."],
    rules: {
      required: (value) => !!value || "Password is required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
    fullnameRule: [(v) => !!v || "Company Name is required"],
  }),
  methods: {
    ...mapActions(["register", "businessRegister"]),
    async validate() {
      this.loading = true;
      if (this.$route.params.slug == "user") {
        if (this.$refs.form.validate()) {
          let userData = {
            fullname: this.fullname,
            email: this.email,
            password: this.password,
          };
          await this.register(userData);
          this.snackbar = true;
        }
      } else {
        if (this.$refs.form.validate()) {
          let userData = {
            businessName: this.fullname,
            email: this.email,
            password: this.password,
          };
          await this.businessRegister(userData);
          this.snackbar = true;
        }
      }
      this.loading = false;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    logout() {
      this.$store.dispatch("logoutUser");
    },
    backBtn() {
      this.$router.go(-1);
    },
  },
  created() {
    if (localStorage.getItem("token") != undefined) {
      this.$router.push("/dashboard");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.logo {
  img {
    width: 200px;
  }
  margin-bottom: 10px;
  @media only screen and (max-width: 992px) {
    display: none;
  }
}
.boxauthoriation {
  padding: 30px 15px;
}
.message {
  margin-top: 15px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  @media only screen and (min-width: 992px) {
    font-size: 14px;
    margin-top: 20px;
    a {
      color: #000;
      font-weight: 600;
    }
  }
}
.registerlink {
  margin-top: 15px;
  a {
    margin-left: 5px;
    color: #000;
    font-weight: 600;
  }
}

.submitbtn {
  text-align: center;
}
img {
  width: 150px;
}
.form-section {
  .col-md-6,
  .col-md-12 {
    padding: 0 20px !important;
  }
}
.titleform {
  margin-bottom: 20px;
}
.registerlink {
  margin-top: 20px;
  font-size: 14px;
}
.btn-dark {
  background: $btnColor !important;
  width: 95%;
  height: 50px !important;
}
</style>
